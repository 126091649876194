<template>
    <AppHeader/>
    <div class="projectBody">
        <div class="projectFormContent">
            <h1 class="titleProject">{{this.$route.params.title}}</h1>
            <h2 v-if="this.$route.params.reference != 'null'" class="descriptionProject">{{this.$route.params.reference}}</h2>
            <div class="container">
                <formie-form :handle="formHandle" class="w-full lg:w-9/12 mx-auto" />
            </div>
        </div>
    </div>
</template>

<script>
import qs from 'qs';
import AppHeader from './AppHeader.vue'

import FormieForm from '@/components/FormieForm.vue';

export default {
    components: {
        FormieForm,
        AppHeader
    },

    data() {
        return {
            // Set the default form via its handle as an .env variable
            formHandle: process.env.VUE_APP_CRAFT_DEFAULT_FORM,
        };
    },

    created() {
        // Allow a `?form=handle` query param to be passed in, to quickly preview different forms
        if (location.search.length) {
            const queryString = qs.parse(location.search, { ignoreQueryPrefix: true });

            if (queryString && queryString.form) {
                this.formHandle = queryString.form;
            }
        }
    },
    mounted() {
        document.getElementsByClassName("backArrow")[0].style.display = "block";
        this.$store.dispatch('fetchUser');
    }

};

</script>
