import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueCirrus from 'vue-cirrus';
import 'vue-cirrus/dist/vue-cirrus.css';

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import { faUserSecret } from '@fortawesome/free-solid-svg-icons'

import { ApolloClient, InMemoryCache } from '@apollo/client/core';
import { createApolloProvider } from '@vue/apollo-option';

import "@/css/global.css"
import './registerServiceWorker'

/* add icons to the library */
library.add(faUserSecret)

const apolloProvider = createApolloProvider({
    defaultClient: new ApolloClient({
        uri: process.env.VUE_APP_CRAFT_GQL_URL,
        cache: new InMemoryCache(),
    }),
});

createApp(App).use(store).use(router).use(apolloProvider).use(VueCirrus).component('font-awesome-icon', FontAwesomeIcon).mount('#app')

router.beforeEach((to) => {
    // Check for token
    if (to.meta.requiresAuth) {
        if (!localStorage.getItem("TeamleaderToken")) {
            router.push("/login");
            return false;
        }
    }
})