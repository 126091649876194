<template>
  <AppHeader/>
  <div class="DisplayBlockMid">
    <div class="resetPasswordView">
      <div class="forgotPasswordPicture">
        <img src="@/assets/forgotpassword.jpg" class="forgotPasswordPic" alt="forgot password picture"/>
      </div>
      <div class="forgotPasswordView">
        <div>
          <h1 class="forgotPassText">Wachtwoord vergeten?</h1>
          <h2 class="forgotPassText">Geen probleem!</h2>
        </div>
        <p id="contactMessage">Neem contact op met <span>wesley@kolu.be</span> om te zorgen dat je opnieuw kan inloggen!</p>
        <router-link :to="'/login'">
          <button type="button" class="buttonStyling">Terug naar login</button>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
import AppHeader from './AppHeader.vue'

export default {
  components: {
    AppHeader
  },
  mounted() {
    document.getElementsByClassName("backArrow")[0].style.display = "none";
    document.getElementsByClassName("logoutIcon")[0].style.display = "none";
    document.getElementById("emailText").style.display = "none";
  }
}
</script>