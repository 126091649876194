<template>
  <div class="flexMid">
    <div class="leftSideLargeScreen">
      <div class="dataminerTextBlock" >
        <h3 class="dataminerTitle">Dataminer</h3>
        <p class="dataminerText">De tool om gegevens te verwerken</p>
      </div>
      <div class="leftSideLargeScreenImage flexMid">
        <img
          src="@/assets/remote-team.png"
          class="logoLeftScreen"
          alt="People illustration"
        />
      </div>
    </div>
    <div class="loginFlexDisplay loginScreenPadding">
      <div class="logoSection">
        <img src="@/assets/Kolu_logo.png" class="logo" alt="Kolu logo" />
      </div>
      <section>
        <h1 class="signInHeader marginBetweenFields">Aanmelden</h1>
        <div class="welcomeText">Welkom terug!</div>
        <div class="welcomeText">Druk op de knop om in te loggen.</div>
        <div class="logOutMessage flexMid marginBetweenFields">
          U bent succesvol uitgelogd.
        </div>
        <!--<div class="floatLeft">
        <p>
          E-mail
        </p>
      </div>
      <div class="input-control loginField marginBetweenFields">
        <input type="email" class="input-contains-icon" id="emailInputField" placeholder="Typ hier je e-mail" /><span class="icon"><i class="fa fa-wrapper fa-envelope"></i></span>
      </div>
      <div class="floatLeft">
        <p>
          Wachtwoord
        </p>
      </div>
      <div class="input-control loginField marginBetweenFields">
        <input type="password" class="input-contains-icon" id="passwordInputField" placeholder="Typ hier je wachtwoord" /><span class="icon"><i class="fa fa-wrapper fa-lock"></i></span>
      </div>-->
        <div class="loginButton">
          <button
            @click="showTeamleaderLogin()"
            type="button"
            class="buttonStyling"
          >
            Login met teamleader
          </button>
        </div>
        <p
          class="forgotPasswordText"
          @click="$router.push({ path: '/passwordreset' })"
        >
          Wachtwoord vergeten?
        </p>
      </section>
      <footer class="footerText">Alpine Digital 2022</footer>
    </div>
  </div>
</template>
<script>
import router from "@/router";

export default {
  methods: {
    showTeamleaderLogin() {
      this.$store.dispatch("showTeamleaderLogin");
    },
  },
  async mounted() {
    if (this.$route.query.code) {
      await this.$store
        .dispatch("getAccessTokenForUser", this.$route.query.code)
        .then(() => {
          router.push("/");
        });
    }
    if (localStorage.getItem("TeamleaderToken")) {
      router.push("/");
    }
    //check for token => redirect to projects
  },
};
</script>
