<template>
    <header class="projectHeader flexHeader">
        <div class="backToProjects" @click="$router.push('/')">
            <i class="fa fa-arrow-left fa-2x backArrow"></i>
        </div>
        <img src="@/assets/koluwhite.png" @click="$router.push({ path: '/' })" class="logoInHeader" alt="Kolu logo white"/>
        <div class="logoutSection flexEnd">
            <p id="emailText">{{this.$store.state.emailAddress}}</p>
            <i @click="logoutAction()" class="fa fa-sign-out fa-2x logoutIcon" aria-hidden="true"></i>
        </div>
    </header>
</template>
<script>
import router from '@/router';

    export default {
        methods: {
            logoutAction() {
                //uitloggen bij teamleader
                //remove token from localstorage
                localStorage.removeItem("TeamleaderToken");
                //redirecten to login
                router.push("/login");
            }
        }
    }
</script>