//
// Credit to Dave Stockley (magicspon)
// https://github.com/magicspon
//

import gql from 'graphql-tag';
import { FormDataJson } from 'form-data-json-convert';
import { flatMap, isPlainObject } from 'lodash-es';

export const getFormFieldMeta = (form) => {
    const allRows = flatMap(form.pages, 'rows');
    const allFields = flatMap(allRows, 'rowFields');

    const fields = flatMap(allFields, (o) => {
        if (o.inputTypeName == "[]" && o.typeName === "Field_FileUpload") {
          o.inputTypeName = "[FileUploadInput]";
        }

        if (o.inputTypeName == "[]" && o.typeName === "Field_Table") {
          o.inputTypeName = `[${o.handle}_TableRowInput]`;
        }
        return o;
    });

    return fields;
};

function createMutationHandle(form) {
    return `save_${form.handle}_Submission`;
}

function createMutationTypes(form) {
    const types = getFormFieldMeta(form).map(({ handle, inputTypeName }) => {
        return `$${handle}: ${inputTypeName}`;
    });

    // Add in any captcha tokens generated when we queried the form.
    form.captchas.forEach((captcha) => {
        types.push(`$${captcha.handle}: FormieCaptchaInput`);
    });

    return types.join(', ');
}

function createMutationValues(form) {
    const values = flatMap(getFormFieldMeta(form), 'handle').map((key) => {
        return `${key}: $${key}`;
    });

    // Add in any captcha tokens generated when we queried the form.
    form.captchas.forEach((captcha) => {
        values.push(`${captcha.handle}: $${captcha.handle}`);
    });

    return values.join(', ');
}

export const getFormMutation = (form) => {
    const mutationTypes = createMutationTypes(form);
    const mutationHandle = createMutationHandle(form);
    const mutationValues = createMutationValues(form);

    return gql`
        mutation FormMutation(${mutationTypes}) {
            ${mutationHandle}(${mutationValues}) {
                id
            }
        }
    `;
};

export const getMutationVariables = async (form, el) => {
    const object = FormDataJson.formToJson(el);
    // Get the mutation types to ensure we cast everything properly
    const mutationTypes = getFormFieldMeta(form);
    const allUploads = document.querySelectorAll("#upload");
    let fileInputIdx = 0;

    for (const info of mutationTypes) {
      let value = object[info.handle];

      if (info.inputTypeName === "String") {
        if (typeof value == "undefined") {
          value = "";
        }
      }

      if (info.inputTypeName.includes("repeater_FormieRepeaterInput")) {
        if (typeof value == "undefined") {
          value = Object.values("");
        }
      }

      if (info.inputTypeName.includes("TableRowInput")) {
        if (typeof value == "undefined") {
          value = Object.values("");
        }
      }

      if (info.inputTypeName === "[FileUploadInput]") {
        const uploads = allUploads[fileInputIdx].files;
        const arrayOfBase64 = await fileListToBase64(uploads);

        let files = [];

        for (let i = 0; i < arrayOfBase64.length; i++) {
          files.push({
            fileData: arrayOfBase64[i],
            filename: uploads[i].name,
          });
        }

        value = files;

        fileInputIdx++;
      }

      if (typeof value === "undefined") {
        return;
      }

      // Fix up any objects that look like arrays
      if (isPlainObject(value)) {
        if (typeof value[0] !== "undefined") {
          value = Object.values(value);
        }
      }

      if (info.inputTypeName === "Int") {
        value = parseInt(object[info.handle], 10);
      }

      if (info.inputTypeName === "[Int]") {
        if (isPlainObject(value)) {
          value = Object.values(value);
        }

        value = value.map((item) => {
          return parseInt(item, 10);
        });
      }

      if (info.inputTypeName === "Number") {
        value = Number(object[info.handle]);
      }

      if (info.inputTypeName === "[Number]") {
        if (isPlainObject(value)) {
          value = Object.values(value);
        }

        value = value.map((item) => {
          return Number(item);
        });
      }

      object[info.handle] = value;
    }

    // Add in any captcha tokens generated when we queried the form.
    form.captchas.forEach((captcha) => {
        object[captcha.handle] = {
            name: captcha.name,
            value: captcha.value,
        };
    });

    return object;
};

async function fileListToBase64(fileList) {
    function getBase64(file) {
      const reader = new FileReader()
      return new Promise(resolve => {
        reader.onload = ev => {
          resolve(ev.target.result)
        }
        reader.readAsDataURL(file)
      })
    }

    const promises = []
  
    for (let i = 0; i < fileList.length; i++) {
      promises.push(getBase64(fileList[i]))
    }
  
    return await Promise.all(promises)
  }
