import { createStore } from "vuex";
import router from "@/router";

const headers = { Accept: "application/json" };
export default createStore({
  state: {
    token: "",
    id: "",
    firstName: "",
    lastName: "",
    emailAddress: "",
    projects: [],
    error: "",
  },
  getters: {

  },
  mutations: {
    _addToken(state, payload) {
      state.token = payload.token;
    },
    _addId(state, payload) {
      state.id = payload.id;
    },
    _addEmail(state, payload) {
      state.emailAddress = payload.email;
    },
    _addLastName(state, payload) {
      state.lastName = payload.lastName;
    },
    _addFirstName(state, payload) {
      state.firstName = payload.firstName;
    },
    _updateError(state, payload) {
      state.error = payload.error;
    },
    _addProjects(state, payload) {
      state.projects = payload;
    },
    _emptyProjects(state) {
      state.projects = [];
    },
  },
  actions: {
    showTeamleaderLogin: function () {
      fetch(`${process.env.VUE_APP_CRAFT_GQL_URL}login`, {
        method: "GET",
        headers: headers,
      })
        .then((response) => {
          if (response.status == 200) {
            response.json().then((json) => {
              document.location.href = json.loginURL;
            });
          } else {
            throw `error with status ${response.status}`;
          }
        })
        .catch((error) => {
          this.state.commit("_updateError", { error: error });
        });
    },

    getAccessTokenForUser(state, code) {
      return new Promise((resolve) => {
        fetch(
          `${process.env.VUE_APP_CRAFT_GQL_URL}login?code=` + code,
          {
            method: "GET",
            headers: headers,
          }
        )
          .then((response) => {
            if (response.status == 200) {
              response.json().then((json) => {
                localStorage.setItem("TeamleaderToken", json.accessToken);
                state.commit("_addEmail", { email: json.user.data.email });
                state.commit("_addFirstName", {
                  firstName: json.user.data.first_name,
                });
                state.commit("_addLastName", {
                  lastName: json.user.data.last_name,
                });
                resolve(json);
              });
            } else {
              throw `error with status ${response.status}`;
            }
          })
          .catch((error) => {
            state.commit("_updateError", { error: error });
          });
      });
    },

    fetchProjects(state) {
      state.commit("_emptyProjects");
      return new Promise((resolve) => {
      fetch("https://api.focus.teamleader.eu/deals.list", {
        method: "POST",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("TeamleaderToken"),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          filter: {
            status: ["open", "won"],
          },
          page: {
            size: 100,
            number: 1,
          },
          sort: [
            {
              field: "created_at",
            },
          ],
        })
      })
        .then((response) => {
          if (response.status == 200) {
            response.json().then((json) => {
              state.commit("_addProjects", { projects: json.data });
              resolve(json);
            });
          } else {
            if (response.status == 401) {
              localStorage.removeItem("TeamleaderToken");
              router.push("/login");
            }
            throw `error with status ${response.status}`;
          }
        })
        .catch((error) => {
          state.commit("_updateError", { error: error });
        });
      })
    },

    fetchUser(state) {
      return new Promise((resolve) => {
        fetch(
          'https://api.focus.teamleader.eu/users.me',
          {
            method: "POST",
            headers: {
              Authorization: "Bearer " + localStorage.getItem("TeamleaderToken"),
              Accept: "application/json",
            },
          }
        )
          .then((response) => {
            if (response.status == 200) {
              response.json().then((json) => {
                state.commit("_addToken", { token: json.accessToken });
                state.commit("_addId", { id: json.data.id });
                state.commit("_addEmail", { email: json.data.email });
                state.commit("_addFirstName", {
                  firstName: json.data.first_name,
                });
                state.commit("_addLastName", {
                  lastName: json.data.last_name,
                });
                resolve(json);
              });
            } else {
              if (response.status == 401) {
                localStorage.removeItem("TeamleaderToken");
                router.push("/login");
              }
              throw `error with status ${response.status}`;
            }
          })
          .catch((error) => {
            state.commit("_updateError", { error: error });
          });
      });
    }
  },
  modules: {},
});
