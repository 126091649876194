<template>
    <div v-if="state === 'success'" class="rounded-md bg-green-50 p-4 sm:px-6">
    <!--SUCCESS STYLING HERE-->
        
        <!-- <div class="flex">
            <div class="flex-shrink-0">
                <svg class="h-5 w-5 text-green-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" /></svg>
            </div>

            <div class="ml-3">
                <p class="text-sm font-medium text-green-800" v-html="successText"></p>
            </div>

            <div class="ml-auto pl-3">
                <div class="-mx-1.5 -my-1.5">
                    <button type="button" class="inline-flex bg-green-50 rounded-md p-1.5 text-green-500 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-green-50 focus:ring-green-600" @click.prevent="onClose">
                        <span class="sr-only">Dismiss</span><svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" /></svg>
                    </button>
                </div>
            </div>
        </div> -->
    </div>

    <div v-if="state === 'error'" class="rounded-md bg-red-50 p-4 sm:px-6">
        <div class="flex">
            <div class="flex-shrink-0 flexError">
                <!--<svg class="h-5 w-5 text-red-400 errorSymbolOnForm" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clip-rule="evenodd" /></svg>-->
                <img class="errorSymbolOnForm" src="@/assets/error.png" alt="error picture"/>
            </div>

            <div class="errorTextBlock">
                <p class="text-sm font-medium text-red-800" v-html="errorText"></p>
            </div>

            <div class="ml-auto pl-3" id="errorMessageBlock" >
                <div class="-mx-1.5 -my-1.5 flexError">
                    <!--<button type="button" class="inline-flex bg-red-50 rounded-md p-1.5 text-red-500 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-red-50 focus:ring-red-600" @click.prevent="onClose">
                        Sluit
                    </button>-->
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    props: {
        successText: {
            type: String,
            required: true,
        },

        errorText: {
            type: String,
            required: true,
        },

        modelValue: {
            type: [String, Boolean],
            default: null,
        },
    },

    emits: ['update:modelValue'],

    computed: {
        state: {
            get() {
                return this.modelValue;
            },

            set(newValue) {
                this.$emit('update:modelValue', newValue);
            },
        },
    },

    methods: {
        onClose() {
            this.state = null;
        },
    },
};

</script>
