<template>
  <AppHeader />
  <section class="searchBar">
    <div class="form-group">
      <input
        type="search"
        class="form-group-input"
        id="searchField"
        placeholder="Geef hier een zoekterm mee [Druk Enter]"
        v-model="searchValue"
        @change="projectsList()"
      />
    </div>
  </section>
  <!--Name and description headers-->
  <div class="nameAndDescriptionHeader">
    <p id="nameTag">Naam</p>
    <p>Referentie</p>
  </div>
  <!--All projects-->
  <section v-if="this.projects?.length" class="allProjectsSection">
    <!--A project-->
    <router-link
      v-for="(project, index) in projects"
      v-bind:key="index"
      class="projectBlock"
      :to="
        'project/' + project.id + '/' + project.title + '/' + project.reference
      "
    >
      <div class="flexMidVertically">
        <div class="projectTitle">{{ project.title }}</div>
        <div class="projectDescription">
          {{ project.reference }}
        </div>
      </div>
      <div class="flexRight">
        <span class="arrowIcon icon-right"
          ><i class="fa fa-wrapper fas fa-chevron-right" aria-hidden="true"></i
        ></span>
      </div>
    </router-link>
  </section>
  <section v-if="this.projects.length == 0 && this.isLoaded" class="allProjectsSection">
    <div class="flexMidVertically">
      <div class="projectTitle">Geen deals gevonden voor bovenstaande filter</div>
    </div>
  </section>
</template>
<style></style>
<script>
import AppHeader from "./AppHeader.vue";
import router from "@/router";

export default {
  data() {
    return {
      searchValue: "",
      projects: [],
      isLoaded: false,
    };
  },
  components: {
    AppHeader,
  },
  async mounted() {
    document.getElementsByClassName("backArrow")[0].style.display = "none";
    if (localStorage.getItem("TeamleaderToken")) {
      await this.$store
        .dispatch("fetchProjects")
        .then(() => (this.projects = this.$store.state.projects.projects));
    } else {
      router.push("/login");
    }
  },
  methods: {
    async projectsList() {
      if (this.searchValue != "") {
        return new Promise((resolve) => {
          fetch("https://api.focus.teamleader.eu/deals.list", {
            method: "POST",
            headers: {
              Authorization:
                "Bearer " + localStorage.getItem("TeamleaderToken"),
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              filter: {
                term: `${this.searchValue}`,
                status: ["open", "won"],
              },
              page: {
                size: 100,
                number: 1,
              },
              sort: [
                {
                  field: "created_at",
                },
              ],
            }),
          }).then((response) => {
            if (response.status == 200) {
              response.json().then((json) => {
                this.projects = json.data;
                resolve(json);
                this.isLoaded = true;
              });
            } else {
              if (response.status == 401) {
                localStorage.removeItem("TeamleaderToken");
                router.push("/login");
              }
              throw `error with status ${response.status}`;
            }
          });
        });
      } else {
        this.projects = this.$store.state.projects.projects;
      }
    },
    // computed: {
    // return this.projects.filter(
    //   (project) =>
    //     project.title
    //       ?.toLowerCase()
    //       .includes(this.searchValue.toLowerCase()) ||
    //     project.reference
    //       ?.toLowerCase()
    //       .includes(this.searchValue.toLowerCase())
    // );
    //}
    // },
  },
};
</script>
