<template>
  <AppHeader/>
  <div class="DisplayBlockMid">
    <div class="submitScreenBlock">
      <div>
        <img src="@/assets/success.png" class="successImage" alt="Succes logo"/>
      </div>
        <h1 class="submitMessage">Je antwoord is verstuurd!</h1>
        <div>
          <p class="submitSubMessage">Bedankt voor het invullen van het formulier voor: </p>
          <p class="submitMessageProjectTitle">{{this.$route.params.title}}</p>
        </div>
        <button @click="$router.push({ path: '/' })" type="button" class="buttonStyling">Terug naar projecten</button>
    </div>
  </div>
</template>

<script>
import AppHeader from './AppHeader.vue'

export default {
  components: {
    AppHeader
  },
  
}
</script>
