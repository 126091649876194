import { createRouter, createWebHashHistory } from "vue-router";
import LogInView from "../views/LogInView.vue";
import MainPageView from "../views/MainPageView.vue";
import ProjectView from "../views/ProjectView.vue";
import ResetPasswordView from "../views/ResetPasswordView.vue";
import SubmitView from "../views/SubmitScreenView.vue";
import PageNotFound from "../views/PageNotFound.vue";

const routes = [
  {
    path: "/login",
    name: "Login",
    component: LogInView,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/",
    name: "MainPage",
    component: MainPageView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/project/:id/:title/:reference",
    name: "Project",
    component: ProjectView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/passwordreset",
    name: "PasswordReset",
    component: ResetPasswordView,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/submit/:title",
    name: "Submit",
    component: SubmitView,
    meta: {
      requiresAuth: true,
    },
  },
  { path: '/:pathMatch(.*)', component: PageNotFound },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
